














import {
	Component,
	Vue,
	Emit
} from "vue-property-decorator";
import routers from '@/router/children'

@Component
export default class extends Vue {

	private routers = routers

	get menuRouters() {
		return this.routers.filter(route => {
			return route.meta.menu
		})
	}

	get menuOne() {
		return this.menuRouters.slice(0, 4)
	}
	get menuTwo() {
		return this.menuRouters.slice(4, 6)
	}

	get curPath() {
		return this.$route.path
	}

	@Emit('navigation')
	navigation(item: any) {
		item.path !== this.$route.path && this.$router.push(item.path)
	}

}
