/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'down': {
    width: 64,
    height: 64,
    viewBox: '0 0 1024 1024',
    data: '<defs/><path pid="0" d="M207.826 384.273l48.265-48.287L560.06 639.954l-48.288 48.242z" fill="#BEBEBE"/><path pid="1" d="M464.1 639.727L768.046 335.78l48.287 48.265-303.969 303.968z" fill="#BEBEBE"/>'
  }
})
