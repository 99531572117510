/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'search_t': {
    width: 200,
    height: 200,
    viewBox: '0 0 1024 1024',
    data: '<defs/><path pid="0" d="M849.34 799.882c66.621-77.688 106.876-178.65 106.876-289.016C956.216 265.533 757.334 66.65 512 66.65S67.784 265.532 67.784 510.866 266.666 955.082 512 955.082c110.367 0 211.328-40.254 289.015-106.876l96.869 96.868c13.344 13.344 34.98 13.344 48.324 0s13.344-34.98 0-48.324l-96.868-96.868zm-337.027 82.154c-204.818 0-370.855-166.038-370.855-370.856s166.037-370.855 370.855-370.855S883.17 306.363 883.17 511.18 717.13 882.036 512.313 882.036z" fill="#BEBEBE"/>'
  }
})
