// 回到页面顶部
export const backTop = () => {
  const timer = setInterval(() => {
      const scrollBox = document.getElementsByTagName('body')[0]
      if (scrollBox) {
          const osTop = scrollBox.scrollTop
          const ispeed = Math.floor(-osTop / 5);
          scrollBox.scrollTop = osTop + ispeed;
          if(osTop === 0){
              clearInterval(timer)
          }
      }
  }, 30)
}

//设置标题
export const setDocumentTitle = (title: string) => {
    const titleElement = document.querySelector('title');
    if (titleElement) titleElement.innerText = title;
}

//px转rem
export const pxTorem = (px: number) => {
    const html = document.getElementsByTagName('html')[0]
    const dpr = Number(html.getAttribute('data-dpr'))
    const fontSize = Number(html.style.fontSize.split('px')[0])
    return (px/fontSize*dpr).toFixed(5) + 'rem'
}