import request from '@/utils/request'

// 获取验证码
export const getSmsCode = async(mobile:string)=>{
  const res = await request({
    url: '/heygood-crm/sale/outApi/FeiYuCallBack/sendNoteVerifyCode',
    method: 'GET',
    params: {
      mobile
    }
  })
  return res.data.success
}

// 报名
interface SignUpParams{
  groupName: string
  industryCode: string
  mobile: string
  noteCode: string
  sourceCode: number,
  openId ?: string | (string | null)[]
}
export const signUp = async (data:SignUpParams) => {
  console.log(data)
  const res = await request({
    url: '/heygood-crm/sale/outApi/FeiYuCallBack/syncCommonRegisterData',
    method: 'POST',
    data
  })
  return res.data.success
}

//查询客户案例
export const getCases = async ({pageNo=1,pageSize=12}) => {
  const res = await request({
      url: '/heygood-crm/api/website/webCaseItem/list',
      method: 'GET',
      params:{pageNo,pageSize}
  })
  return res.data.result
}

// 文章标签
export const getNewsLabel = async () => {
  const res = await request({
      url: '/heygood-crm/api/website/webInformation/getDict',
      method: 'GET'
  })
  return res.data.result
}

// 资讯列表
export const getNews = async ({informationType='',pageNo=1,pageSize=20}) => {
  const res = await request({
      url: '/heygood-crm/api/website/webInformation/list',
      method: 'GET',
      params: {informationType,pageNo,pageSize}
  })
  return res.data.result
}

//资讯详情
export const getNewDetail = async (id:any) => {
  const res = await request({
      url: '/heygood-crm/api/website/webInformation/detail',
      method: 'GET',
      params: {id}
  })
  return res.data.result
}