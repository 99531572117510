import store from '../store'
export default {
  methods: {
    Loading(value: boolean){ 
      store.commit('SET_LOADING', value)
    },
    Message(type: string, message: string){
      store.commit('SET_MESSAGE', {visiable: true, message, type})
      const timer = setTimeout(()=>{
        store.commit('SET_MESSAGE_VISIABLE', false)
        clearTimeout(timer)
      }, 2000)
    }
  }
}