import router from '@/router'
import { backTop, setDocumentTitle } from '@/utils/utils'

router.beforeEach((to:any, from, next) => {
  backTop()
  // to.meta && setDocumentTitle(to.meta.title);
  if (to.meta.content) {
    document.querySelector('meta[name="keywords"]')?.setAttribute('content', to.meta.content.keywords);
    document.querySelector('meta[name="description"]')?.setAttribute('content', to.meta.content.description);
  }
  if (Object.keys(from.query).length > 0) {
    window.location.href = `${to.fullPath}` + from.fullPath.replace(from.path, '');
  } else {
    next();
  }
  // if(from.query.openId){
  //   let href = ''
  //   if(Object.keys(to.query).length > 0){
  //     href = `${to.fullPath}&openId=${from.query.openId}`
  //   } else {
  //     href = `${to.fullPath}?openId=${from.query.openId}`
  //   }
  //   window.location.href = href
  // }else{
  //   next()
  // }
})
