/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'menu': {
    width: 60,
    height: 60,
    viewBox: '0 0 60 60',
    data: '<g fill="none" fill-rule="evenodd"><rect pid="0" fill="#222" fill-rule="nonzero" width="60" height="60" rx="30"/><g stroke="#BEBEBE" stroke-linecap="round" stroke-linejoin="round" stroke-width="3"><path pid="1" d="M18 22.5h24M18 30.5h24M18 38.5h24"/></g></g>'
  }
})
