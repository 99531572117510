














import { Component, Vue } from 'vue-property-decorator'
import Main from './components/main.vue'
import Footer from './components/footer.vue'
import Fixed from './components/fixed.vue'
import Header from './components/header.vue' 
// import Apply from './components/apply.vue' //旧版
import Apply from './components/pages2/newApply.vue' //新版
import vMessage from '@/components/v-message.vue'

@Component({
  components: { Header, Main, Footer, vMessage, Apply, Fixed }
})
export default class extends Vue{

  get loading(){
    return this.$store.getters.loading
  }
  get messageVisiable(){
    return this.$store.getters.messageVisiable
  }

}
