/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'close': {
    width: 60,
    height: 60,
    viewBox: '0 0 60 60',
    data: '<g fill-rule="nonzero" fill="none"><rect pid="0" fill="#222" width="60" height="60" rx="30"/><g stroke="#BEBEBE" stroke-linecap="round" stroke-linejoin="round" stroke-width="3"><path pid="1" d="M36.483 23.217L22.918 36.783M36.483 36.783L22.917 23.218"/></g></g>'
  }
})
