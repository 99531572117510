/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'right': {
    width: 46,
    height: 26,
    viewBox: '0 0 46 26',
    data: '<g transform="translate(0 3)" fill="none" fill-rule="evenodd"><path pid="0" stroke="#FFF" stroke-width="4" stroke-linecap="round" stroke-linejoin="round" d="M10.029 10.277h31.344"/><g stroke="#FFF" stroke-linecap="round" stroke-linejoin="round" stroke-width="4"><path pid="1" d="M42.162 10.139l-9.301 9.301M41.945 9.921L32.86.838"/></g><circle pid="2" fill="#FFF" cx="2" cy="10" r="2"/></g>'
  }
})
