

















import {
	Component,
	Prop,
	Vue
} from "vue-property-decorator";
import vSearch from "@/components/v-search.vue";
// import VMenu from "../../components/v-menu.vue"; //旧版
import VMenu from "../../components/pages2/v-menu-new.vue"; //新版

@Component({
	components: {
		vSearch,
		VMenu
	},
})
export default class extends Vue {

	@Prop({
		default: 'black'
	}) bgColor!: string

	get backgroundColor() {
		// return this.bgColor == 'black' ? "#000000" : "#1E1E1E"
		if(this.$route.path == '/newHome'){			
			if(this.menuVisible == false){
				return "#000000"
			}
			else{
				return "#1E1E1E"
			}
		}
		else{
			return "#1E1E1E"
		}	
	}

	private searchVisible = false
	private menuVisible = false

}
