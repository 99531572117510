export default {
  state:{
    loading: false,
    messageVisiable: false,
    message: '默认消息',
    messageType: 'Info'
  },
  mutations: {
    SET_LOADING (state:{ loading: boolean }, value: boolean):void {
      state.loading = value
    },
    SET_MESSAGE (state:{ messageVisiable: boolean, message: string, messageType: string }, data: any):void {
      state.messageVisiable = data.visiable
      state.message = data.message
      state.messageType = data.type
    },
    SET_MESSAGE_VISIABLE(state:{messageVisiable:string}, value:string){
      state.messageVisiable = value
    }
  }
}