
















import {
	Component,
	Vue,
	Prop,
	Emit
} from "vue-property-decorator";

@Component
export default class extends Vue {
	private visiable = false
	private curValue = ''
	private curName = ''

	@Prop() options!: any[]
	@Prop() mode!: string
	@Emit('select') select(item: any) {
		this.curValue = item.value
		this.curName = item.name
		this.visiable = false
		return item.value
	}

}
