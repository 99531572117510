/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'top': {
    width: 64,
    height: 64,
    viewBox: '0 0 1024 1024',
    data: '<defs/><path pid="0" d="M512 149.333c200.299 0 362.667 162.368 362.667 362.667S712.299 874.667 512 874.667 149.333 712.299 149.333 512 311.701 149.333 512 149.333zm5.333 232.086L322.752 576 368 621.248l149.333-149.333 149.334 149.333L711.915 576 517.333 381.419z" fill="#0057FF"/>'
  }
})
