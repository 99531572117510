






import { Component, Vue } from 'vue-property-decorator'
import { backTop } from "../../utils/utils"

@Component({})
export default class extends Vue{
  backTop(){
    backTop()
  }
}
