







import { Component, Vue } from "vue-property-decorator";

@Component
export default class extends Vue {

  private keyword = "";

  search():void {
    alert(this.keyword)
  }
  
}
