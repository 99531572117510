import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'
import Layout from '@/layout/index.vue'
import children from './children'

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
	// {
	//   path:"/*",
	//   component: () => import("@/views/update/index.vue"),
	//   meta: {  icon: "" },
	// },
	{
		path: '/',
		component: Layout,
		// redirect: '/home',
		redirect: '/newHome',
		children: children
	},
	{
		path: '/login',
		component: () => import('@/views/home/index.vue'),
		meta: { title: '登录' }
	},
	{
		path: '/500',
		component: () => import('@/views/home/index.vue'),
		meta: { title: '500' }
	},
	{
		path: '*',
		// component: () => import('@/views/home/index.vue'),
		component: Layout,
		redirect: '/newHome',
		meta: { title: '404' }
	}, {
		path: '/spread',
		name: 'spread',
		component: () => import('@/views/spread/index.vue'),
		meta: { title: '合伙人', menu: false }
	}
]


const router = new VueRouter({
	mode: 'history',
	base: process.env.BASE_URL,
	routes
})

export default router
