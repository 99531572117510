/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'search': {
    width: 60,
    height: 60,
    viewBox: '0 0 60 60',
    data: '<g fill="none" fill-rule="evenodd"><rect pid="0" fill="#222" fill-rule="nonzero" width="60" height="60" rx="30"/><path pid="1" d="M39.363 37.796l4.1 4.218c.716.7.716 1.934 0 2.634l.18-.177c-.715.705-1.782.705-2.498 0l-4.28-4.396c-2.138 1.406-4.816 2.285-7.489 2.285C22.062 42.364 16 36.39 16 29.182 16 21.98 21.887 16 29.196 16c7.314 0 13.376 5.98 13.376 13.182 0 3.34-1.247 6.33-3.209 8.614zm-9.987 1.934c5.882 0 10.698-4.746 10.698-10.548 0-5.801-4.816-10.547-10.703-10.547S18.67 23.38 18.67 29.182c.004 5.802 4.82 10.548 10.707 10.548z" fill="#BEBEBE"/></g>'
  }
})
