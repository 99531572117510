






import { Component, Vue, Prop, Emit } from "vue-property-decorator";

@Component
export default class extends Vue {

  get messageVisiable(){
    return this.$store.getters.messageVisiable
  }
  get message(){
    return this.$store.getters.message
  }
  get messageType(){
    return this.$store.getters.messageType
  }

  get color(){
    if(this.messageType == 'info'){
      return '#0057FF'
    }
    if(this.messageType == 'error'){
      return '#FF5A2D'
    }
    return '#0057FF'
  }

}
