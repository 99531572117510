import store from '@/store';
import axios from 'axios'

const Error = (message: string) => {
    store.commit('SET_MESSAGE', { visiable: true, message: message, type: 'error' })
    const timer = setTimeout(() => {
        store.commit('SET_MESSAGE_VISIABLE', false)
        clearTimeout(timer)
    }, 2000);
}
const getPath = (): string => {
    const origin = location.origin;
    if (origin.indexOf('localhost') > -1 || origin.indexOf('192.168') > -1)
        return 'https://heygood-crm-test.xmheigu.com';
    return 'https://heygood-crm.xmheigu.com';
}
const URL = getPath()

const service = axios.create({
    baseURL: URL,
    timeout: 50000,
    responseType: 'json',
    // 向后端发送请求
    transformRequest: [(request, header) => {
        header.post['Content-Type'] = 'application/json;charset=UTF-8'
        return request && request.append ? request : JSON.stringify(request)
    }],
    // 后端返回数据
    transformResponse: [response => response]
});

// 请求响应前拦截
service.interceptors.request.use(
    config => {
        store.commit('SET_LOADING', true)
        return config
    },
    error => {
        return Promise.reject(error)
    }
);

// 请求响应后拦截
service.interceptors.response.use(response => {
    store.commit('SET_LOADING', false)
    const { data } = response
    console.log('service.interceptors.response', data);
    if (Number(data.code) !== 200) {
        Error(data.message)
        return Promise.reject(data);
    } else {
        return Promise.resolve(data.data ? data : response);
    }
}, error => {
    Error(error.message)
    return Promise.reject(error)
});
export default service;
