export default [
	// 2022-01-04 新版 开始
	{
		path: '/newHome',
		name: 'newHome',
		component: () => import('@/views/pages2/newHome/index.vue'),
		meta: {
			title: '首页',
			menu: true
		}
	},
	{
		path: '/newCases',
		name: 'newCases',
		component: () => import('@/views/pages2/newCases/index.vue'),
		meta: {
			title: '客户案例',
			menu: true
		}
	},
	{
		path: '/dynamic',
		name: 'dynamic',
		component: () => import('@/views/pages2/dynamic/dynamic.vue'),
		meta: {
			title: '黑谷动态',
			menu: true
		}
	},
	{
		path: '/dynamicDetails/:id',
		name: 'dynamicDetails',
		component: () => import('@/views/pages2/dynamic/dynamicDetails.vue'),
		meta: {
			title: '资讯详情',
			menu: false
		}
	},
	{
		path: '/newCulture',
		name: 'newCulture',
		component: () => import('@/views/pages2/newCulture/index.vue'),
		meta: {
			title: '企业文化',
			menu: true
		}
	},
	{
		path: '/about',
		name: 'about',
		component: () => import('@/views/pages2/about/about.vue'),
		meta: {
			title: '加入我们',
			menu: true
		}
	},
	{
		path: '/connection',
		name: 'connection',
		component: () => import('@/views/pages2/about/connection.vue'),
		meta: {
			title: '联系我们',
			menu: true
		}
	},
	
	// 2022-01-04 新版 结束
	
	{
		path: '/home',
		name: 'Home',
		component: (): any => import('@/views/home/index.vue'),
		meta: {
			title: '首页',
			menu: true
		}
	},
	{
		path: '/introduce',
		name: 'Introduce',
		component: () => import('@/views/introduce/index.vue'),
		meta: {
			title: '公司简介',
			menu: true
		}
	},
	{
		path: '/culture',
		name: 'Culture',
		component: () => import('@/views/culture/index.vue'),
		meta: {
			title: '企业文化',
			menu: true
		}
	},
	{
		path: '/classroom',
		name: 'Classroom',
		component: () => import('@/views/classroom/index.vue'),
		meta: {
			title: '实战课堂',
			menu: true
		}
	},
	{
		path: '/shop',
		name: 'Shop',
		component: () => import('@/views/shop/index.vue'),
		meta: {
			title: '共享店铺',
			menu: true
		}
	},
	{
		path: '/business',
		name: 'Business',
		component: () => import('@/views/business/index.vue'),
		meta: {
			title: '共享商圈',
			menu: true
		}
	},
	{
		path: '/staff',
		name: 'Staff',
		component: () => import('@/views/staff/index.vue'),
		meta: {
			title: '超级员工',
			menu: true
		}
	},
	{
		path: '/trill',
		name: 'Trill',
		component: () => import('@/views/trill/index.vue'),
		meta: {
			title: '抖音运营',
			menu: true
		}
	},
	{
		path: '/software',
		name: 'Software',
		component: () => import('@/views/soft/index.vue'),
		meta: {
			title: '管理软件',
			menu: true
		}
	},
	{
		path: '/granary',
		name: 'Granary',
		component: () => import('@/views/granary/index.vue'),
		meta: {
			title: '黑谷粮仓',
			menu: true
		}
	},
	// {
	//   path: '/customer',
	//   name: 'Customer',
	//   component: () => import('@/views/customer/index.vue'),
	//   meta:{title:'超级获客',menu:true}
	// },
	// {
	//   path: '/night',
	//   name: 'Night',
	//   component: () => import('@/views/night/index.vue'),
	//   meta:{title:'黑谷之夜',menu:true}
	// },
	{
		path: '/case',
		name: 'Case',
		component: () => import('@/views/cases/index.vue'),
		meta: {
			title: '客户案例',
			menu: true
		}
	},
	{
		path: '/news/:id',
		name: 'newDetail',
		component: () => import('@/views/news/detail/index.vue'),
		meta: {
			title: '资讯详情',
			menu: false
		}
	},

]
