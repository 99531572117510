/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'trill_01': {
    width: 60,
    height: 58,
    viewBox: '0 0 60 58',
    data: '<g fill="#0058FE" fill-rule="evenodd" opacity=".797"><rect pid="0" x="10" y="10" width="40" height="40" rx="1"/><rect pid="1" opacity=".203" x="40" width="20" height="20" rx="1"/><rect pid="2" opacity=".203" y="38" width="20" height="20" rx="1"/></g>'
  }
})
