









import { Component, Vue } from 'vue-property-decorator'
import vApplyNew from '@/components/pages2/v-apply-new.vue'

@Component({
  components: { vApplyNew }
})
export default class extends Vue{
  private applyVisible = false
  private visiable = true
  get content(){
    const date = new Date()
    let year = date.getFullYear()
    let mounth = date.getMonth() + 2
    if (mounth > 12) {
      mounth -= 12;
      year += 1;
    }

    return `${year}年${mounth}月实战课程即将开课！`
  }
}
